<template>
  <div>
    <head-top-other :title="title"></head-top-other>
    <div class="page">
      <div class="wrap">
        <div class="line-1">
          <!-- <div :class="['line-1-item', index===0? 'active':'']" @click="loginTabChange(0)">邮箱登录</div> -->
          <div
            :class="['line-1-item', index === 1 ? 'active' : '']"
            @click="loginTabChange(1)"
          >
            手机登录
          </div>
        </div>
        <el-form
          key="email"
          :model="emailForm"
          ref="emailForm"
          :rules="emailRules"
          v-if="index === 0"
        >
          <el-form-item prop="account">
            <el-input
              v-model="emailForm.account"
              key="email"
              autocomplete="off"
              placeholder="请输入邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item prop="secret">
            <el-input
              type="password"
              key="email"
              v-model="emailForm.secret"
              autocomplete="off"
              placeholder="密码"
            ></el-input>
          </el-form-item>
          <el-button class="submit" @click="onSubmit('emailForm')"
            >立即登录</el-button
          >
        </el-form>
        <el-form
         @submit.native.prevent
          key="tel"
          :model="telForm"
          ref="telForm"
          :rules="telRules"
          v-else
        >
          <el-form-item prop="account">
            <el-input
              v-model="telForm.account"
              key="tel"
              autocomplete="off"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="secret">
            <el-input
              type="password"
              key="tel"
              v-model="telForm.secret"
              autocomplete="off"
              placeholder="密码"
            ></el-input>
          </el-form-item>
          <el-button class="submit" native-type="submit" @click="onSubmit('telForm')"
            >立即登录</el-button
          >
        </el-form>
        <div class="line-2">
          <!-- <router-link class="forget" :to="{ path: '/login/forget-password' }"
            >忘记密码</router-link
          > -->
          <router-link class="register" :to="{ path: '/login/register' }"
            >免费注册</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clientToken, getUserInfoByToken } from '@/api/index';
import HeadTopOther from '@/components/HeadTopOther';
import { mapActions } from 'vuex';
export default {
  name: 'Login',
  data() {
    return {
      index: 1,
      title: '登录',
      emailForm: {
        account: '',
        secret: '',
        type: 101,
      },
      emailRules: {
        account: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
        secret: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      telForm: {
        account: '',
        secret: '',
        type: 101,
      },
      telRules: {
        account: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
        ],
        secret: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
    };
  },
  components: {
    HeadTopOther,
  },
  methods: {
    ...mapActions(['changSetToken', 'changSetInfo']),
    loginTabChange(index) {
      this.index = index;
    },
    getUserInfoByTokenFunc(data) {
      getUserInfoByToken(data).then((res) => {
        this.changSetInfo(res.data[0]);
      });
    },
    onSubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          clientToken(this[form]).then((response) => {
            if (response.code === 200 && response.data[0]) {
              this.getUserInfoByTokenFunc({ token: response.data[0].token });
              this.changSetToken(response.data[0].token);
              this.$router.replace({
                path: '/',
              });
            } else {
              this.$message({
                message: response.message || '登录失败',
                type: 'error',
              });
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  background: url(../../assets/index/icon-005.png) no-repeat;
  background-size: 100% 100%;
  padding: 260px 0 140px;
  .wrap {
    margin-left: 61.25%;
    width: 380px;
    height: 330px;
    background: rgba(255, 255, 255, 1);
    text-align: center;
    .line-1 {
      display: flex;
      border-bottom: 1px solid rgba(204, 204, 204, 1);
      &-item {
        cursor: pointer;
        text-align: center;
        width: 100%;
        height: 62px;
        line-height: 62px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        &.active {
          background: rgba(111, 15, 149, 1);
          color: rgba(255, 255, 255, 1);
        }
      }
    }
    :deep(.el-form) {
      padding: 30px;
    }
    .submit {
      width: 320px;
      height: 40px;
      background: rgba(111, 15, 149, 1);
      border-radius: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 40px;
      text-align: center;
      padding: 0;
      display: inline-block;
    }
    .line-2 {
      margin-top: -15px;
      padding: 0 30px 30px;
      display: flex;
      justify-content: flex-end;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 30px;
      .forget {
        margin-right: 5%;
      }
    }
  }
}
</style>